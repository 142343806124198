import User, { type Permission, type Role } from '~/models/User'
import { useUserStore } from '~/stores/user'
import logger from '~/services/LoggerService'
import { useCallback, useMemo } from 'react'
import { globalApi } from '~/services/APIService'
import { useTenantStore } from '~/stores/tenant'

const DEFAULT_TENANT = 'kale'

export const useUser = () => {
  const user = useUserStore()
  const tenant = useTenantStore()

  const fetchUser = async (): Promise<User | null> => {
    try {
      const result = await globalApi.$get('me')
      const data = new User(result)

      loginUser(data)

      return data
    } catch (e) {
      //
    }

    return null
  }

  const loginUser = (data: User) => {
    user.login(data)
    tenant.setUserTenantUid(data.tenant.uid)

    logger.debug('User', data)
  }

  return {
    user: user.data,
    userTenantUid: tenant.userTenantUid,
    fetchUser,
    loginUser
  }
}

export const useAcl = () => {
  const { user, userTenantUid } = useUser()

  const has = useCallback<(roles: Role | Role[]) => boolean>(
    (roles) => {
      roles = Array.isArray(roles) ? roles : [roles]

      const activeRoles = user?.activeRoles || user?.roles || []

      return roles.some((role) => activeRoles.includes(role))
    },
    [user?.roles, user?.activeRoles]
  )

  const can = useCallback<(permission: Permission) => boolean>(
    (permission: Permission) => {
      if (has('admin')) {
        return true
      }

      return user?.permissions.includes(permission) ?? false
    },
    [user?.permissions]
  )

  const isSuperAdmin = useMemo<boolean>(() => {
    return has('admin') && userTenantUid === DEFAULT_TENANT
  }, [has, userTenantUid])

  const isSuperSuperAdmin = useMemo<boolean>(() => {
    return isSuperAdmin && !!user?.email && !['lukas@trykale.com'].includes(user.email)
  }, [isSuperAdmin, user?.email])

  // either operator or admin
  const isManager = useMemo<boolean>(() => has(['admin']), [has])

  return {
    can,
    has,
    isManager,
    isSuperAdmin,
    isSuperSuperAdmin
  }
}

export type AclType = ReturnType<typeof useAcl>
